import React, { useEffect, useRef } from "react";
import StudySidebar from "./StudySidebar";
import StudyMainPanel from "./StudyMainPanel";

const StudyControlPanel = ({
  setTabs,
  tabs,
  handleAddNewTab,
  userCurrentData,
  currentUserData,
}) => {
  const xResizerRef = useRef(null);
  useEffect(() => {
    const xResizer = xResizerRef.current;

    const handleMouseMoveX = (e) => {
      const clientX = e.clientX;
      const deltaX = clientX - (xResizer._clientX || clientX);
      xResizer._clientX = clientX;

      const l = xResizer.previousElementSibling;
      const r = xResizer.nextElementSibling;

      if (deltaX < 0) {
        const w = Math.round(parseInt(getComputedStyle(l).width) + deltaX);
        l.style.flex = `0 ${w < 10 ? 0 : w}px`;
        r.style.flex = "1 0";
      } else if (deltaX > 0) {
        const w = Math.round(parseInt(getComputedStyle(r).width) - deltaX);
        r.style.flex = `0 ${w < 10 ? 0 : w}px`;
        l.style.flex = "1 0";
      }
    };

    const handleMouseUpX = () => {
      document.removeEventListener("mousemove", handleMouseMoveX);
      document.removeEventListener("mouseup", handleMouseUpX);
      delete xResizer._clientX;
    };

    const handleMouseDownX = (e) => {
      e.preventDefault();
      document.addEventListener("mousemove", handleMouseMoveX);
      document.addEventListener("mouseup", handleMouseUpX);
    };

    xResizer.addEventListener("mousedown", handleMouseDownX);

    return () => {
      xResizer.removeEventListener("mousedown", handleMouseDownX);
    };
  }, []);

  return (
    <>
      <div className="p-1" style={{ maxHeight: "calc(100vh - 72px)" }}>
        <div className="resizable-x">
          <div style={{ flex: "0 0 15%" }}>
            <StudySidebar handleAddNewTab={handleAddNewTab} tabs={tabs} />
          </div>
          <div className="resizer-x" ref={xResizerRef}></div>
          <div className="overflow-auto" style={{ flex: "0 0 84.5%" }}>
            <StudyMainPanel
              userCurrentData={userCurrentData}
              currentUserData={currentUserData}
              tabs={tabs}
              setTabs={setTabs}
              handleAddNewTab={handleAddNewTab}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyControlPanel;
