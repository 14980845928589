import React, { useState } from "react";

const ScaleDesign = () => {
  const [colorValue, setColorValue] = useState(50);

  const colorScale = (t) => {
    const t0 = [48, 18, 59];
    const t1 = [22, 79, 121];
    const t2 = [57, 134, 145];
    const t3 = [120, 183, 143];
    const t4 = [190, 228, 125];
    const t5 = [249, 253, 139];
    const t6 = [254, 233, 87];
    const t7 = [252, 174, 50];
    const t8 = [241, 103, 40];
    const t9 = [217, 33, 32];

    const colors = [t0, t1, t2, t3, t4, t5, t6, t7, t8, t9];
    const n = colors.length - 1;

    const index = t * n;
    const index0 = Math.floor(index);
    const index1 = Math.ceil(index);
    const weight = index - index0;

    const color0 = colors[index0];
    const color1 = colors[index1];

    const r = interpolate(color0[0], color1[0], weight);
    const g = interpolate(color0[1], color1[1], weight);
    const b = interpolate(color0[2], color1[2], weight);

    return [r, g, b];
  };

  const interpolate = (a, b, t) => {
    return a + (b - a) * t;
  };

  const handleChange = (e) => {
    setColorValue(e.target.value);
  };

  const inputStyle = {
    alignSelf: "start",
    width: "60vw",
  };

  
  const rgb = colorScale(colorValue / 100);

  const containerStyle = {
    backgroundColor: `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`,
    display: "grid",
    justifyItems: "center",
    gridGap: "5px",
  };


  const paletteStyle = {
    width: "60vw",
    height: "50px",
    border: "1px solid #000",
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    alignSelf: "end",
  };

  const colorBlockStyle = (backgroundColor) => ({
    backgroundColor,
    borderRight: "1px solid #000",
  });

  return (
    <>
    <div style={containerStyle}>
      <div style={paletteStyle}>
        <div style={colorBlockStyle("rgb(48, 18, 59)")}></div>
        <div style={colorBlockStyle("rgb(22, 79, 121)")}></div>
        <div style={colorBlockStyle("rgb(57, 134, 145)")}></div>
        <div style={colorBlockStyle("rgb(120, 183, 143)")}></div>
        <div style={colorBlockStyle("rgb(190, 228, 125)")}></div>
        <div style={colorBlockStyle("rgb(249, 253, 139)")}></div>
        <div style={colorBlockStyle("rgb(254, 233, 87)")}></div>
        <div style={colorBlockStyle("rgb(252, 174, 50)")}></div>
        <div style={colorBlockStyle("rgb(241, 103, 40)")}></div>
        <div style={colorBlockStyle("rgb(217, 33, 32)")}></div>
      </div>
      {/* <input
        type="range"
        id="color"
        min="0"
        max="100"
        value={colorValue}
        onChange={handleChange}
        style={inputStyle}
      /> */}
    </div>
    </>
  );
};

export default ScaleDesign;
