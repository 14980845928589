import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "react-bootstrap";

const Header = ({ userCurrentData }) => {
  const toggleRef = useRef(null);
  const [profileImage, setProfileImage] = useState("");

  useEffect(() => {
    setProfileImage(
      userCurrentData?.profileImage && userCurrentData?.profileImage
    );
  }, [userCurrentData]);

  return (
    <>
      <Navbar expand="lg" className="bg-color px-2">
        <div ref={toggleRef} className="hstack justify-content-between w-100">
          <div className="fw-bold" style={{ color: "#b3df43" }}>
            Clinical Trial
          </div>
          <div className={`profileDropdown fs-13`}>
            <div className="hstack gap-3">
              <p className="m-0 fw-bold">Time Zone : (GMT+5:30)</p>
              <p className="m-0 fw-bold">Login as : Client</p>
              <span className="hstack gap-2">
                <img
                  src={profileImage}
                  alt="Profile"
                  className="rounded-circle border"
                  title="User Profile"
                  style={{
                    width: 20,
                    height: 20,
                    objectFit: "cover",
                  }}
                />
                <span className="fw-bold">{userCurrentData?.fullName}</span>
              </span>
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
