import React from 'react'
import Toolbar from './Toolbar/Toolbar';
import { Col, Container, Row } from 'react-bootstrap';
import EditorMainPage from './EditorMainpage/EditorMainPage';

const FormEditor = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2}>
            <div>
              <Toolbar />
            </div>
          </Col>
          <Col md={10}>
            <div className='text-center'>
              <EditorMainPage />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default FormEditor
