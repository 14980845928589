import React from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";

const AddStudyForm = ({
  companyName,
  setCompanyName,
  shortName,
  setShortName,
  website,
  address,
  setAddress,
  phone,
  setPhone,
  officePhone,
  setOfficePhone,
  setWebsite,
  phoneError,
  setPhoneError,
  createSponserId,
  city_id,
  setCityID,
  state_id,
  setStateID,
  country_id,
  setCountryID,
  city,
  postalCode,
  setPostalCode,
  Country,
  state,
  Show,
  Hide,
  Title,
}) => {
  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits!");
    }
  };

  return (
    <div>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Company Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Short Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Short Name"
              value={shortName}
              onChange={(e) => setShortName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Website <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Website Name"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Phone <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Phone number"
              className={
                (phoneError && "border-danger text-danger") ||
                (phone && "border-success")
              }
              onChange={(e) => {
                setPhone(e.target.value);
                validatePhoneNumber(e.target.value);
              }}
              value={phone}
            />
            {phoneError && phone && (
              <Form.Text className="fs-10 m-0 text-danger">
                {phoneError}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Office Phone <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Office Phone"
              value={officePhone}
              onChange={(e) => setOfficePhone(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Country Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setCountryID(e.target.value);
              }}
              value={country_id}
            >
              <option>-Select-</option>
              {Country?.length > 0 &&
                Country.map((item, key) => (
                  <option value={item.id} key={key} title={item.id}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              State Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => {
                setStateID(e.target.value);
              }}
              value={state_id}
            >
              <option>-select-</option>
              {state?.length > 0 &&
                state.map((item, key) => (
                  <option value={item.id} key={key} title={item.id}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              City Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setCityID(e.target.value)}
              value={city_id}
            >
              <option>-select-</option>
              {city?.length > 0 &&
                city.map((item, key) => (
                  <option value={item.id} key={key}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Address <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter your address"
              className={address && "border-success"}
              style={{ height: "29px" }}
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Zip Code <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="Enter Postel Code"
              className={postalCode && "border-success"}
              onChange={(e) => setPostalCode(e.target.value)}
              value={postalCode}
            />
          </Form.Group>
          <Form.Group className="text-end">
            <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
              <FaPlusSquare style={{ marginBottom: 2 }} />{" "}
              {!createSponserId ? "Add" : "Update"} Client
            </button>
          </Form.Group>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddStudyForm;
