import React from 'react'
import { Card, Form } from 'react-bootstrap'
import { BsCalendar2Date } from 'react-icons/bs'
import { CgRadioChecked } from 'react-icons/cg'
import { GrCheckboxSelected } from 'react-icons/gr'
import { RiCheckboxMultipleFill } from 'react-icons/ri'
import { LiaHeadingSolid, LiaTapeSolid } from 'react-icons/lia'
import { MdCloudUpload, MdMoreTime, MdMovieEdit, MdOutlineFormatListNumberedRtl, MdOutlineKeyboardHide } from 'react-icons/md'

const Toolbar = () => {
    return (
        <>
            <Card className="mb-3 bg-light border mt-3">
                <Card.Body>
                    <div className='mb-2 text-center' style={{ fontWeight: 700 }}>
                        TOOLS
                    </div>
                    <hr className='m-0 mb-2 w-100'/>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <LiaHeadingSolid />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Heading</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <BsCalendar2Date />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Date</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <MdMoreTime />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Time</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <MdOutlineKeyboardHide />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Text Input [short]</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <MdOutlineFormatListNumberedRtl />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Number Input</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <MdMovieEdit />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Text Area [long]</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <RiCheckboxMultipleFill />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Checkbox</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <CgRadioChecked />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Radio</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <GrCheckboxSelected />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Select</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <MdCloudUpload />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>upload File</Form.Text>
                    </Form.Group>
                    <Form.Group className='d-flex gap-2 border-bottom mb-2 CP'>
                        <Form.Label style={{ fontSize: 16 }}>
                            <LiaTapeSolid />
                        </Form.Label>
                        <Form.Text style={{ fontWeight: 500 }}>Scale</Form.Text>
                    </Form.Group>
                </Card.Body>
            </Card>
        </>
    )
}

export default Toolbar
