import React from "react";
import { Offcanvas, Table } from "react-bootstrap";

const FormAttibuteData = ({ Show, Hide, Title, tableRef, onMouseDown }) => {
  return (
    <>
      <div>
        <Offcanvas show={Show} onHide={Hide} placement="end">
          <Offcanvas.Header>
            <Offcanvas.Title>{Title}</Offcanvas.Title>
            <div onClick={Hide} className="close_modal" title="Close">
              X
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Table
              id="resizable-table"
              striped
              hover
              bordered
              className="m-0 custom-table"
              ref={tableRef}
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: "100px" }}
                  >
                    Attribute
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Value</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Company Name</td>
                  <td className="text-capitalize">Company 1</td>
                </tr>
                <tr>
                  <td>Full Name</td>
                  <td className="text-capitalize">John Deo</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td className="text-capitalize">123, local street USA</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>+1 12547</td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>https://www.comapny.com</td>
                </tr>
                <tr>
                  <td>Zip Code</td>
                  <td className="text-capitalize">2568401</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td className="text-capitalize">USA</td>
                </tr>
              </tbody>
            </Table>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default FormAttibuteData;
