import React, { useState } from 'react'
import { FaRegEdit } from 'react-icons/fa';
import ScaleDesign from './Scale/ScaleDesign';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Card, Collapse, Form } from 'react-bootstrap';

const EditorMainPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleCollapse = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <div className='overflow-auto mt-3' style={{ height: "calc(100vh - 142px)" }}>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Heading<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(0)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Control
                type="text"
                style={{ backgroundColor: 'white' }}
                placeholder="Enter Heading Name"
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 0}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Edit Heading</Form.Label>
            </div>
            <Form.Control
              type='text'
              placeholder="Enter Heading Name"
            />
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Date<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(1)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Control
                type="date"
                placeholder="Enter Date"
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 1}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Edit Date</Form.Label>
            </div>
            <Form.Control
              type='date'
              placeholder="Enter Date"
            />
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Time<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(2)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Control
                type="time"
                placeholder="Enter Time"
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 2}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Edit Time</Form.Label>
            </div>
            <Form.Control
              type='time'
              placeholder="Enter Time"
            />
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Text Input<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(3)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter Text"
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 3}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Text Input</Form.Label>
            </div>
            <Form.Control
              type='text'
              placeholder="Enter Text"
            />
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Number Input<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(4)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter Number"
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 4}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Number Input</Form.Label>
            </div>
            <Form.Control
              type='number'
              placeholder="Enter Number"
            />
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Text Area<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(5)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Control
                as="textarea"
                placeholder="Write here..."
                rows={4}
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 5}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Text Area</Form.Label>
            </div>
            <Form.Control
              as="textarea"
              placeholder="Write here..."
              rows={4}
            />
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Checkbox<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(6)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Check
                style={{ width: "fit-content" }}
                required
                type='checkbox'
                label="Checkbox 1"
              />
              <Form.Check
                style={{ width: "fit-content" }}
                required
                type='checkbox'
                label="Checkbox 2"
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 6}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Checkbox</Form.Label>
            </div>
            <Form.Check
              style={{ width: "fit-content" }}
              required
              type='checkbox'
              label="Checkbox 1"
            />
            <Form.Check
              style={{ width: "fit-content" }}
              required
              type='checkbox'
              label="Checkbox 2"
            />
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Radio<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(7)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Check
                style={{ width: "fit-content" }}
                required
                type='radio'
                label="radio 1"
              />
              <Form.Check
                style={{ width: "fit-content" }}
                required
                type='radio'
                label="radio 2"
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 7}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Radio</Form.Label>
            </div>
            <Form.Check
              style={{ width: "fit-content" }}
              required
              type='radio'
              label="radio 1"
            />
            <Form.Check
              style={{ width: "fit-content" }}
              required
              type='radio'
              label="radio 2"
            />
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Select<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(8)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Select>
                <option value="">Select </option>
                <option value=""> 1</option>
                <option value=""> 2</option>
              </Form.Select>
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 8}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Select</Form.Label>
            </div>
            <Form.Select>
              <option value="">Select </option>
              <option value=""> 1</option>
              <option value=""> 2</option>
            </Form.Select>
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between'>
                <Form.Label className="fw-bold">
                  Upload File<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} onClick={() => toggleCollapse(9)} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <Form.Control
                type="file"
                placeholder='upload file'
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Collapse in={openIndex === 9}>
          <Form.Group className='mb-2'>
            <div className='text-start'>
              <Form.Label className="fw-bold">Upload File</Form.Label>
            </div>
            <Form.Control
              type="file"
              placeholder='upload file'
            />
          </Form.Group>
        </Collapse>
        <Card className="mb-2 bg-light border">
          <Card.Body>
            <Form.Group className="mb-2">
              <div className='d-flex justify-content-between mb-1'>
                <Form.Label className="fw-bold">
                  Scale<span className="text-danger">*</span>
                </Form.Label>
                <div className='mb-1'>
                  <FaRegEdit className='CP' style={{ marginRight: 5 }} />
                  <RiDeleteBin6Line className='text-danger CP' />
                </div>
              </div>
              <ScaleDesign />
            </Form.Group>
          </Card.Body>
        </Card>
        <div className='text-end position-sticky bottom-0 bg-white shadow-sm me-2'>
          <button className='bg-color fs-13 text-white' style={{ padding: "2px 25px", borderRadius: 3 }}>
            Submit
          </button>
        </div>
      </div>
    </>
  )
}

export default EditorMainPage
