import React from "react";
import { FaUserCheck } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { authServices } from "../APIServices/authServices";
import { TbLogout } from "react-icons/tb";
import { VscAccount } from "react-icons/vsc";
import { SiOpenaccess } from "react-icons/si";
import { BsBuildingCheck } from "react-icons/bs";

const Sidebar = ({ handleAddNewTab, tabs }) => {
  const logout = async () => {
    await authServices.logout();
  };

  return (
    <>
      <div className="border rounded-1 bg-light overflow-hidden">
        <div
          className="overflow-auto"
          style={{ height: "calc(100vh - 115px)" }}
        >
          <div
            className={`cursor-pointer p-2 ${tabs.some(
              (item) =>
                item.tabId === "dashboardId" && item.status === "active"
            ) && "activeMenu"
              }`}
            onClick={() =>
              handleAddNewTab(
                "Dashboard",
                "fa-solid fa-chart-line",
                "dashboardId"
              )
            }
          >
            <div className="hstack justify-content-between" style={{ whiteSpace: "nowrap" }}>
              <span>
                <MdDashboard />
                <span className="ms-2 fs-13">Dashboard</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${tabs.some(
              (item) => item.tabId === "clientID" && item.status === "active"
            ) && "activeMenu"
              }`}
            title="Client"
            onClick={() =>
              handleAddNewTab("Client", "fa-solid fa-building-circle-check", "clientID")
            }
          >
            <div className="hstack justify-content-between" style={{ whiteSpace: "nowrap" }}>
              <span>
                <BsBuildingCheck />
                <span className="ms-2 fs-13">Client Onboarding</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${tabs.some(
              (item) =>
                item.tabId === "userDataID" && item.status === "active"
            ) && "activeMenu"
              }`}
            title="User"
            onClick={() =>
              handleAddNewTab("User", "fa-solid fa-user-check", "userDataID")
            }
          >
            <div className="hstack justify-content-between" style={{ whiteSpace: "nowrap" }}>
              <span>
                <FaUserCheck />
                <span className="ms-2 fs-13">User Onboarding</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${tabs.some(
              (item) =>
                item.tabId === "accessManagementId" &&
                item.status === "active"
            ) && "activeMenu"
              }`}
            onClick={() =>
              handleAddNewTab(
                "Access Management",
                "fa-solid fa-lock",
                "accessManagementId"
              )
            }
          >
            <div className="hstack justify-content-between" style={{ whiteSpace: "nowrap" }}>
              <span>
                <SiOpenaccess />
                <span className="ms-2 fs-13">Access Management</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${tabs.some(
              (item) =>
                item.tabId === "accountSettingsId" && item.status === "active"
            ) && "activeMenu"
              }`}
            onClick={() =>
              handleAddNewTab(
                "Account Settings",
                "fa-solid fa-circle-user",
                "accountSettingsId"
              )
            }
          >
            <div className="hstack justify-content-between" style={{ whiteSpace: "nowrap" }}>
              <span>
                <VscAccount />
                <span className="ms-2 fs-13">Account Settings</span>
              </span>
            </div>
          </div>
        </div>
        <div className={`cursor-pointer p-1 bg-danger-subtle`} onClick={logout}>
          <div className="hstack justify-content-between" style={{ whiteSpace: "nowrap" }}>
            <span>
              <TbLogout />
              <span className="ms-2 fs-13">Logout</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
