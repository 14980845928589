import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { BsBuildingCheck } from "react-icons/bs";
import { FaBookReader, FaFolderOpen, FaPlusSquare, FaUserCheck } from "react-icons/fa";
import Clients from "./Chart/Clients";
import Users from "./Chart/Users";
import Projects from "./Chart/Projects";
import Studies from "./Chart/Studies";
import { NavLink } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <div className="py-2">
        <Container fluid>
          <Row className="chart__overview">
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <BsBuildingCheck className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>170</Card.Title>
                      <Card.Text>Total Clients</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaUserCheck className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>150</Card.Title>
                      <Card.Text>Total User</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaFolderOpen className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>20</Card.Title>
                      <Card.Text>Total Project</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <FaBookReader className="rounded-1 bg-white shadow-sm " />
                    </div>
                    <div>
                      <Card.Title style={{ fontSize: 20 }}>45</Card.Title>
                      <Card.Text>Total Study</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 400 }} className="text-center">
                  <Card.Text>Client Summery</Card.Text>
                  <Clients />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 400 }} className="text-center">
                  <Card.Text>User Summery</Card.Text>
                  <Users />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 400 }} className="text-center">
                  <Card.Text>Project Summery</Card.Text>
                  <Projects />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} xl={3} lg={4} md={6} sm={12}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 400 }} className="text-center">
                  <Card.Text>Study Summery</Card.Text>
                  <Studies />
                </Card.Body>
              </Card>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Card className="mb-3 bg-light border">
                <Card.Body style={{ height: 350 }}>
                  <Card.Text className="text-center">Study Login</Card.Text>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Clients <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select>
                      <option value="">Select Clients</option>
                      <option value="">Clients 1</option>
                      <option value="">Clients 2</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Project <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select>
                      <option value="">Select Project</option>
                      <option value="">Project 1</option>
                      <option value="">Project 2</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Study <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select>
                      <option value="">Select Study</option>
                      <option value="">Study 1</option>
                      <option value="">Study 2</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Environment <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select>
                      <option value="">Select Environment</option>
                      <option value="">Environment 1</option>
                      <option value="">Environment 2</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="text-end">
                    <NavLink to={"/study"}>
                    <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
                      Login
                    </button>
                    </NavLink>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Form.Group>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
