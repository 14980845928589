import React from "react";
import { MdDashboard } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { BsBuildingCheck } from "react-icons/bs";
import { authServices } from "../../APIServices/authServices";

const StudySidebar = ({ handleAddNewTab, tabs }) => {
  const logout = async () => {
    await authServices.logout();
  };

  return (
    <>
      <div className="border rounded-1 bg-light overflow-hidden">
        <div
          className="overflow-auto"
          style={{ height: "calc(100vh - 115px)" }}
        >
          <div
            className={`cursor-pointer p-2 ${tabs.some(
              (item) =>
                item.tabId === "dashboardId" && item.status === "active"
            ) && "activeMenu"
              }`}
            onClick={() =>
              handleAddNewTab(
                "Dashboard",
                "fa-solid fa-chart-line",
                "dashboardId"
              )
            }
          >
            <div className="hstack justify-content-between" style={{ whiteSpace: "nowrap" }}>
              <span>
                <MdDashboard />
                <span className="ms-2 fs-13">Dashboard</span>
              </span>
            </div>
          </div>
          <div
            className={`cursor-pointer p-2 ${tabs.some(
              (item) => item.tabId === "formManagement" && item.status === "active"
            ) && "activeMenu"
              }`}
            title="Form Management"
            onClick={() =>
              handleAddNewTab("Form Management", "fa-solid fa-building-circle-check", "formManagement")
            }
          >
            <div className="hstack justify-content-between" style={{ whiteSpace: "nowrap" }}>
              <span>
                <BsBuildingCheck />
                <span className="ms-2 fs-13">Form Management</span>
              </span>
            </div>
          </div>
        </div>
        <div className={`cursor-pointer p-1 bg-danger-subtle`} onClick={logout}>
          <div className="hstack justify-content-between" style={{ whiteSpace: "nowrap" }}>
            <span>
              <TbLogout />
              <span className="ms-2 fs-13">Logout</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudySidebar;
